import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import { inner_cimg, txtbd, hpmainl } from '../../components/layout.module.css'

const BlogPage = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image)
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <div className={hpmainl}>
        <h3 className={txtbd}>{data.mdx.frontmatter.title}</h3>
        <div className={inner_cimg}>
          <GatsbyImage
            image={image}
            alt={data.mdx.frontmatter.hero_image_alt}
          />
        </div>
        <div className={txtbd}>
          <p>Price: {data.mdx.frontmatter.price}</p>
          <p>Status: {data.mdx.frontmatter.availability}</p>
          <p>Product code: {data.mdx.frontmatter.pcode}</p>
          <MDXRenderer>
            {data.mdx.body}
          </MDXRenderer>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    mdx(id: {eq: $id}) {
      body
      frontmatter {
        title        
        date(formatString: "MMMM DD, YYYY")
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        price
        category
        availability,
        pcode
      }
    }
  }
`

export default BlogPage 